/* ./src/index.css */

html {
  font-family: var(--font-sans);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

h1 {
  font-size: var(--font-size-7);
  max-inline-size: none;
}

.navbar {
  padding: var(--size-2);

  .brand {
    display: inline-block;
    padding: var(--size-2);
    margin-right: var(--size-2);
  }
  .nav-link {
    display: inline-block;
    padding: var(--size-2);
    margin: 0 var(--size-2);
  }
}

.content {
  margin: 0 auto;
  width: 83%;
}

.recipes {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-around;
	//max-inline-size: calc(var(--size-content-1) * 4);
	gap: var(--size-5);
	padding: var(--size-5);

	.recipe {
		flex-basis: var(--size-14);
		display: flex;
		flex-direction: column;
		gap: var(--size-2);
		background: var(--surface-3);
		border: 1px solid var(--surface-1);
		padding: var(--size-4);
		border-radius: var(--radius-3);
		box-shadow: var(--shadow-2);

		h3 {
			font-size: var(--font-size-3);
			line-height: var(--font-lineheight-1);
		}
	}
}